import React, { useState } from "react";
import HeaderWrapper from "./Header.style";
import MobileMenu from "../mobileMenu/MobileMenu";
import blokc from "../../../assets/images/Fulllogo.png";
import { HiMenuAlt3 } from "react-icons/hi";
import DropdownDemo from "../dropdownDemo/DropdownDemo";
import ConnectWalletButton from "../../button/ConnectWalletButton";
import TransakWidget from "../../transak/Transak";
import { useWeb3AuthSigner } from "../../context/web3-auth-signer";
import Migrate from "../../button/Migrate";

const Header = () => {
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [isMigrate, setIsMigrate] = useState(false);
  const {
    setOpenModule,
    setAccesscodeopen,
    transakopen,
    setTransak,
    web3AuthSigner,
    accountAddress,
  } = useWeb3AuthSigner();
  const code1 = localStorage.getItem("code");

  const handleMobileMenu = () => {
    setIsMobileMenu(!isMobileMenu);
  };

  const buytoken = () => {
    if (!code1) {
      setAccesscodeopen(true);
    } else if (!web3AuthSigner) {
      setOpenModule(true);
    } else {
      setTransak(true);
    }
  };

  return (
    <>
      <HeaderWrapper className="header-section">
        <div className="container">
          <div className="gittu-header-content">
            <div className="gittu-header-left">
              <a className="gittu-header-logo" href="/">
                <img src={blokc} alt="blokc" className="md:h-12 md:w-full h-8 w-auto" />
                {/* BLOK Capital */}
              </a>

              <DropdownDemo />
            </div>
            <div className="gittu-header-right">
              <div className="gittu-header-menu-toggle">
                <button className="menu-toggler" onClick={handleMobileMenu}>
                  <HiMenuAlt3 />
                </button>
              </div>
              <div className="gittu-header-right-menu">
                <div className="gittu-header-menu" role="button" onClick={() => buytoken()}>
                  <div className="gittu-header-menu" role="button" onClick={() => buytoken()}>
                    Buy Crypto
                  </div>
                </div>
                {accountAddress && (
                  <div className="gittu-header-menu" role="button" onClick={() => setIsMigrate(true)}>
                    Migrate
                  </div>
                )}

                <ConnectWalletButton />
              </div>
            </div>
          </div>
        </div>
      </HeaderWrapper>

      {transakopen && <TransakWidget />}
      {isMobileMenu && <MobileMenu mobileMenuHandle={handleMobileMenu} code1={code1} setIsMigrate={setIsMigrate} />}
      {isMigrate && <Migrate setIsMigrate={setIsMigrate} />}
    </>
  );
};

export default Header;
