/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/rules-of-hooks */
// src/components/Migrate.js
import styled from "styled-components";
import ModalWrapper from "../modal/Modal.style";
import { CgClose } from "react-icons/cg";
import { useWeb3AuthSigner } from "../context/web3-auth-signer";
import React, { useState, useEffect } from "react";
import { FaUser, FaCheckCircle, FaUpload, FaSpinner } from "react-icons/fa";
import { IoMdCreate } from "react-icons/io";
import Button from "./Button";
import { RiArrowDownDoubleFill } from "react-icons/ri";
import { ethers } from "ethers";
import { encodeFunctionData } from "viem";
import { BLOKCAbi } from "../../abi/BLOKCabi";

const StyledCloseButton = styled.div`
  cursor: pointer;
`;

const StyledPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: black;
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  text-align: center;
  max-width: 400px;
  width: 80%;

  a {
    color: #1e90ff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

// Overlay to darken the background
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Migrate = ({ setIsMigrate }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [buttonshow, setButtonshow] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [showCreateButton, setShowCreateButton] = useState(false);
  const [error, setError] = useState(false);
  const {
    migratedata,
    userinfo,
    accountAddress,
    sessionKeyProvider,
    blokcbalace,
  } = useWeb3AuthSigner();
  console.log("🚀 ~ Migrate ~ blokcbalace:", blokcbalace);
  const userEmail = userinfo?.email;

  const userMigrationData = migratedata.find(
    (data) => data.email === userEmail
  );

  const steps = [
    {
      id: "1",
      title: "Check User",
      subtitle: "Verify if user exists",
      subtitle1: "User exists",
      subtitle2: "User does not exist",
      icon: FaUser,
    },
    {
      id: "2",
      title: "User Details",
      subtitle: "Viewing user details",
      icon: IoMdCreate,
    },
    {
      id: "3",
      title: "Transfer BLOKC",
      subtitle: "Transfer BLOKC to account",
      icon: FaUpload,
    },
    {
      id: "4",
      title: "Success",
      subtitle: "Successfully transferred BLOKC",
      icon: FaCheckCircle,
    },
  ];

  useEffect(() => {
    if (currentStep === 0) {
      setLoading1(true);
      setTimeout(() => {
        setLoading1(false);
        if (userMigrationData) {
          setCurrentStep(1);
        } else {
          setCurrentStep(2);
        }
      }, 3000);
    }
  }, [currentStep, userMigrationData]);

  useEffect(() => {
    if (userMigrationData && blokcbalace === 0) {
      setCurrentStep(3);
    }
  }, [userMigrationData, blokcbalace]);

  const handleCreateAccount = () => {
    setLoading1(true);
    setTimeout(() => {
      setCurrentStep(1);
      setLoading1(false);
      setShowCreateButton(false);
    }, 1000);
  };

  const handleTransfer = async () => {
    setError(false);
    setLoading1(true);
    if (blokcbalace !== 0) {
      const contractAddress = "0xBC4d9D3Dfe6AB1D36Ede90050ce96FCB937469f0"; //arbiscan-->usdc new contract address
      const to = userMigrationData.wallet;
      const paymentAmount = blokcbalace;
      const value = ethers.utils.parseUnits(paymentAmount.toString(), 18);
      try {
        const { hash } = await sessionKeyProvider.sendUserOperation({
          target: contractAddress,
          data: encodeFunctionData({
            abi: BLOKCAbi,
            functionName: "transfer",
            args: [to, value],
          }),
        });
        const hash1 = await sessionKeyProvider.waitForUserOperationTransaction(
          hash
        );
        if (hash1) {
          setCurrentStep(3);
          setLoading1(false);
        }
      } catch (error) {
        console.log(error);
        setError(true);
        setLoading1(false);
      }
    } else {
      // setTimeout(() => {
      //   setCurrentStep(3);
      //   setLoading1(false);
      // }, 1000);
    }
  };

  const contiuebutton = () => {
    setLoading1(true);
    setTimeout(() => {
      setCurrentStep(2);
      setButtonshow(true);
      setLoading1(false);
    }, 1000);
  };

  return (
    <>
      {userMigrationData ? (
        <ModalWrapper className="gittu-modal fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50 z-50">
          <div className="gittu-modal-content  rounded-lg shadow-xl max-w-2xl w-full">
            <div className="gittu-modal-header flex justify-between items-center px-6 py-2 border-b border-gray-200">
              <h2 className="text-lg font-medium">Migrate</h2>
              <StyledCloseButton
                role="button"
                onClick={() => setIsMigrate(false)}
              >
                <CgClose size={20} />
              </StyledCloseButton>
            </div>
            <div className="container mx-auto py-8">
              <div className="max-w-4xl mx-auto">
                {blokcbalace == 0.0 ? (
                  <div className="text-center ">
                    You have successfully migrated your Account.
                  </div>
                ) : (
                  <div className="relative">
                    {steps.map((step, index) => (
                      <div key={step.id} className="relative pb-8">
                        {index !== steps.length - 1 && (
                          <div
                            className={`absolute top-4 left-5 w-1 h-full z-0 ${
                              index < currentStep
                                ? "bg-blue-600"
                                : "bg-gray-300"
                            }`}
                          ></div>
                        )}
                        <div className="relative flex items-center z-10">
                          <div className="bg-gray-700 p-2 rounded-full">
                            {index === currentStep && loading1 ? (
                              <FaSpinner className="h-8 w-8 text-white animate-spin" />
                            ) : (
                              <step.icon
                                className={`h-7 w-7 ${
                                  index < currentStep ||
                                  (index === 3 && currentStep === 3)
                                    ? "text-green-500"
                                    : "text-white"
                                } ${
                                  index === currentStep && "text-green-500"
                                } ${index < currentStep && "text-green-500"}`}
                              />
                            )}
                          </div>
                          <div className="ml-4">
                            <h3 className="text-lg font-medium">
                              {step.title}
                            </h3>
                            <p className="text-sm text-gray-400">
                              {currentStep === 0
                                ? userMigrationData
                                  ? step.subtitle1
                                  : step.subtitle2
                                : step.subtitle}
                            </p>
                          </div>
                        </div>
                        <div className="ml-12 mt-2">
                          {index === 1 && currentStep === 1 && (
                            <div className="flex flex-col gap-2">
                              <Migrationdata
                                userMigrationData={userMigrationData}
                                accountAddress={accountAddress}
                              />
                              {userMigrationData ? (
                                <>
                                  {!buttonshow && userMigrationData && (
                                    <Button
                                      onClick={() => contiuebutton()}
                                      className="px-4 py-2 bg-blue-500 text-white rounded"
                                    >
                                      Continue
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <Button
                                  onClick={handleCreateAccount}
                                  disabled={loading1}
                                  className={` px-4 py-2 rounded ${
                                    showCreateButton ? "block" : "hidden"
                                  }`}
                                >
                                  Create Account
                                </Button>
                              )}
                            </div>
                          )}
                          {index === 2 && currentStep === 2 && (
                            <div className="flex flex-col gap-2">
                              <Migrationdata
                                userMigrationData={userMigrationData}
                                accountAddress={accountAddress}
                              />
                              <div className="text-center font-semibold">
                                Total: {blokcbalace ? blokcbalace : "00"} BLOKC
                              </div>
                              <Button
                                onClick={handleTransfer}
                                disabled={loading1}
                                className="px-4 py-2 bg-blue-500 text-white rounded"
                              >
                                {blokcbalace !== 0
                                  ? "Transfer BLOKC"
                                  : "continue"}
                              </Button>
                              {error && (
                                <p className="text-red-700 text-center font-bold">
                                  Tranction Error
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalWrapper>
      ) : (
        <div>
          <Overlay />
          <StyledPopup>
            You have not created a new version account. Please visit
            <a
              href="https://blokc.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              https://blokc.app
            </a>
            , create your account, and then come back to continue the process
            (refresh the page).
          </StyledPopup>
        </div>
      )}
    </>
  );
};

export default Migrate;

const Migrationdata = ({ userMigrationData, accountAddress }) => {
  return (
    <div className="w-full space-y-4 p-4 bg-black rounded-lg shadow-md">
      <div className="flex flex-col items-center">
        <p className="text-base text-gray-300">
          Current Account:
          <span className="block text-lg text-white">
            {accountAddress
              ? `${accountAddress.slice(0, 6)}...${accountAddress.slice(-4)}`
              : "Not Available"}
          </span>
        </p>
      </div>
      <div className="flex justify-center my-2">
        <RiArrowDownDoubleFill size={24} className="text-blue-500" />
      </div>
      <div className="flex flex-col items-center">
        <p className="text-base text-gray-300">
          Migration Account:
          <span className="block text-lg text-white">
            {userMigrationData?.wallet
              ? `${userMigrationData.wallet.slice(
                  0,
                  6
                )}...${userMigrationData.wallet.slice(-4)}`
              : "Not Available"}
          </span>
        </p>
      </div>
    </div>
  );
};

{
  /* <div>
  {userMigrationData ? (
    <div>
      <div className="pb-2">
        <h3 className="text-2xl py-2">User Migration Data</h3>
        <p className="text-sm">
          Transfer your BLOKC tokens from your old wallet to your new wallet by
          migrating your account.{" "}
        </p>
      </div>
      <div className="gittu-modal-body"> */
}
{
  /* <BalanceInfo>
                  <div>BLOKC Balance:</div>
                  <div>{blokcbalace} BLOKC</div>
                </BalanceInfo> */
}

{
  /* <WalletInfo>
                  <div>Old Wallet:</div>
                  <div className="flex ">
                    {accountAddress.slice(0, 6)}...{accountAddress.slice(-4)}
                    <CopyButton
                      size={20}
                      onClick={() => copyToClipboard(accountAddress)}
                    />
                  </div>
                </WalletInfo> */
}
//         <div className="presale-item ">
//           <h6>Current Wallet Address:</h6>
//           <StyledInput type="text" value={accountAddress} disabled />
//         </div>
//         <div className="presale-item ">
//           <h6>New Wallet Address:</h6>
//           <StyledInput type="text" value={userMigrationData.wallet} disabled />
//         </div>
//         <div className="presale-item mb-25">
//           <h6>BLOKC Balance:</h6>
//           <StyledInput type="number" value={blokcbalace} disabled />
//         </div>

//         <div>
//           <div className="d-flex flex-column justify-content-center">
//             <Button
//               variant={loding ? "gadient2" : "gradient"}
//               //onClick={buyToken}
//               className="btn-approve "
//               onClick={() => migrationBLOKC()}
//             >
//               {loding ? (
//                 <div className="spinner-border h-5 w-5" role="status">
//                   <span className="sr-only"></span>
//                 </div>
//               ) : (
//                 "    Migrate Now"
//               )}
//             </Button>
//           </div>
//         </div>
//       </div>
//     </div>
//   ) : (
//     <div>
//       <StyledMessage>
//         You have not created a new version account. Please visit
//         <a href="https://blokc.app/" target="_blank" rel="noopener noreferrer">
//           {" "}
//           https://blokc.app
//         </a>
//         , create your account, and then come back to continue the process.
//       </StyledMessage>
//     </div>
//   )}
// </div>;
