/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import Button from "../button/Button";
import ModalWrapper from "./Modal.style";
import usdcIcon from "../../assets/images/token/usdcIcon.png";
import arbicon from "../../assets/images/COIN-PNG-1y-150x150 (1).png";
import { CgClose } from "react-icons/cg";
import useWallet from "../hooks/use-wallet";
import { useWeb3AuthSigner } from "../context/web3-auth-signer";
import { IoIosInformationCircleOutline } from "react-icons/io";
import axios from "axios";
import { UsdcABI } from "../../abi/usdcabi";
import { encodeFunctionData } from "viem";
import { ClockLoader } from "react-spinners";
import sucess from "../../assets/images/checked.png";
import { MdErrorOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import { ARBabi } from "../../abi/ARBabi";
import { BLOKCAbi } from "../../abi/BLOKCabi";

const Withdrawtoken = ({ setRewardtoken, setOpenprofile, ...props }) => {
  const [selectedOption, setSelectedOption] = useState(1);
  const {
    accountAddress,
    usdcbalace,
    sessionKeyProvider,
    setOpenModule,
    arbbalace,
    blokcbalace,
  } = useWeb3AuthSigner();
  //console.log(userinfo);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [selectedImg, setSelectedImg] = useState(usdcIcon);
  const [tokenname, setTOkenname] = useState("USDC");
  const { data } = useWallet();
  const [isChecked, setChecked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [errorbalace, setErrorbalace] = useState(false);
  const [minbalanceerror, setminbalanceerror] = useState(false);
  const [incheckbox, setIncheckbox] = useState(false);
  const [responsemsg, setResponsemsg] = useState("");
  const [responsepopup, setResponsepopup] = useState(false);
  const [transfererror, setTransfererror] = useState(false);
  const [sucesstrs, setSucesstrs] = useState(false);
  const [sendaddress, setSendaddress] = useState("");

  const handleSendAddressChange = (e) => {
    setSendaddress(e.target.value);
  };

  const handleAmountChange = (e) => {
    const enteredAmount = e.target.value;
    setPaymentAmount(enteredAmount);

    // Check if entered amount is greater than usdcbalace
    if (parseFloat(enteredAmount) > parseFloat(usdcbalace)) {
      setErrorbalace(true);
    } else {
      setErrorbalace(false);
    }

    if (parseFloat(enteredAmount) < 0) {
      setminbalanceerror(true);
    } else {
      setminbalanceerror(false);
    }
  };

  const handleKeyPress = (e) => {
    // Allow only numeric input
    const keyCode = e.which || e.keyCode;
    const isValidInput = (keyCode >= 48 && keyCode <= 57) || keyCode === 46;
    if (!isValidInput) {
      e.preventDefault();
    }
  };

  const transfer = async () => {
    setTransfererror(false);
    console.log("----transfer----");
    if (!errorbalace && !minbalanceerror && sendaddress) {
      console.log("------------trtansfer usdc----------");
      const contractAddress = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831"; //arbiscan-->usdc new contract address
      const to = sendaddress;
      const value = ethers.utils.parseUnits(paymentAmount.toString(), 6);
      //console.log("sendaddress--->", sendaddress);
      //console.log("inputvalue--->", value);
      try {
        setResponsepopup(true);
        const { hash } = await sessionKeyProvider.sendUserOperation({
          target: contractAddress,
          data: encodeFunctionData({
            abi: UsdcABI,
            functionName: "transfer",
            args: [to, value],
          }),
        });
        const hash1 = await sessionKeyProvider.waitForUserOperationTransaction(
          hash
        );
        //const hash1 = "hsjfsjfkfsfksfgsgfjsagf";
        console.log("-----hash1------>", hash1);

        console.log("----------------finale----------");
        setIncheckbox(false);
        if (hash1) {
          //setTimeout(() => {
          //  setSucesstrs(true);
          //}, 5000);
          setSucesstrs(true);
        }
      } catch (error) {
        console.log(error);
        setSucesstrs(false);
        setTransfererror(true);
      }
    }
  };

  const transferdata = () => {
    transfer();
  };

  const checkbalace = () => {
    setOpenprofile(true);
    setResponsepopup(false);
    //setIsModalOpen(false);
    setRewardtoken(false);
  };

  return (
    <>
      <ModalWrapper className="gittu-modal" {...props}>
        <div className="overlay"></div>
        <div className="gittu-modal-content">
          <div className="gittu-modal-header">
            <h4 className="ff-orbitron text-white text-uppercase">
              Withdraw Token
            </h4>
            <button onClick={() => setRewardtoken(false)}>
              <CgClose />
            </button>
          </div>
          <div className="gittu-modal-body">
            <div className="mb-20">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                  <h5 className="ff-outfit fw-600 text-white text-uppercase gap-1 flex ">
                    Balance : {selectedOption === 1 ? usdcbalace : null}
                    <span>{tokenname}</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="input-group">
              <div className="flex justify-between items-center gap-2  w-full my-2">
                {/* one */}
                <div
                  className={`w-full flex justify-center items-center gap-2 border-2 rounded p-2 border-[#ffffff] border-opacity-40 bg-opacity-10 cursor-pointer text-white md:text-base text-xs  ${
                    selectedOption === 1 ? "border-opacity-90" : ""
                  }`}
                  //  onClick={() => handleOptionClick(1, tokenname)}
                >
                  {/* <StyledButton> */}
                  <img src={selectedImg} alt="icon" className="md:h-10 h-8" />
                  <span>{tokenname}</span>
                  {/* </StyledButton> */}
                </div>
              </div>
            </div>
            <div className="presale-item mb-25">
              <h6>Send to</h6>
              <div className="input-group">
                <input
                  type="text"
                  name="payment-amount"
                  id="payment-amount"
                  placeholder="Enter public address (0x)"
                  onChange={handleSendAddressChange}
                />
              </div>
            </div>
            <div className="presale-item mb-25">
              <h6>Amount</h6>
              <input
                type="number"
                name="get-amount"
                id="get-amount"
                placeholder="Enter amount"
                onChange={handleAmountChange}
                onKeyPress={handleKeyPress}
              />
              <div className="flex space-x-4">
                {errorbalace && (
                  <p className="text-sm text-red-600 px-2 ">
                    {" "}
                    Max balance {arbbalace}
                  </p>
                )}
                {minbalanceerror && (
                  <p className="text-sm text-red-600 px-2 ">
                    Min 10 USDC withdraw
                  </p>
                )}
              </div>
            </div>

            <div>
              <div className="d-flex flex-column justify-content-center">
                <Button
                  variant={props.variant === "v2" ? "gadient2" : "gradient"}
                  //onClick={buyToken}
                  className="btn-approve "
                  onClick={transferdata}
                >
                  {incheckbox ? (
                    <div className="spinner-border h-5 w-5" role="status">
                      <span className="sr-only"></span>
                    </div>
                  ) : (
                    "Withdraw"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalWrapper>

      {responsepopup && (
        <>
          <div>
            <ModalWrapper>
              <div className="overlay"></div>
              <div className="gittu-modal-content">
                <div className="gittu-modal-body">
                  {transfererror ? (
                    <>
                      <div className="flex flex-col gap-3 justify-center items-center mb-3">
                        <div className="flex flex-col gap-2 justify-center items-center">
                          <MdErrorOutline size={40} />
                          <p className="text-lg text-red-800">
                            Transaction Failed
                          </p>
                        </div>
                      </div>

                      <div className="flex justify-center gap-2 items-center text-center">
                        <Button
                          className="connect-wallet-btn"
                          variant={"connect"}
                          onClick={() => setResponsepopup(false)}
                        >
                          <span className="px-2">Please try again</span>
                        </Button>
                      </div>
                    </>
                  ) : sucesstrs ? (
                    <>
                      <div className="flex flex-col gap-3 justify-center items-center mb-3">
                        <div className="flex flex-col gap-2 justify-center items-center">
                          <img src={sucess} alt="sucessicon" className="h-12" />
                          <p className="text-lg">Transaction Successful</p>
                        </div>
                        {/*<div className="flex flex-col text-center">
                          <ul className="order-first">
                            <li className="text-lg">
                              You Received: {responsemsg.BLOKCAmount} BLOKC
                            </li>
                            <li className="text-lg">
                              You Paid: {responsemsg.inputTokenAmount}{" "}
                              {tokenname}
                            </li>
                          </ul>
                        </div>*/}
                      </div>

                      <div className="flex justify-center gap-2 items-center text-center">
                        <Button
                          className="connect-wallet-btn"
                          variant={"connect"}
                          onClick={checkbalace}
                        >
                          Check Balance
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-col gap-3 justify-center items-center">
                        <div className="flex flex-col gap-2 justify-center items-center">
                          <ClockLoader color="#ffff" size={40} />
                          <p className="text-lg">Processing...</p>
                        </div>
                        <div className="flex flex-col text-center">
                          <p className="text-lg text-warning">Do Not Refresh</p>
                          <p>Your transaction is being processed...</p>
                          <p>
                            Arbitrum transaction may take few minutes as well...
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </ModalWrapper>
          </div>
        </>
      )}
    </>
  );
};

export default Withdrawtoken;
