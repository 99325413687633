/* eslint-disable no-undef */
import BannerWrapper from "./Banner.style";
import Countdown from "../../../components/countdown/Countdown";
import Progressbar from "../../../components/progressbar/Progressbar";
import Button from "../../../components/button/Button";
import Telegram from "../../../assets/images/icons/telegram.svg";
import Twitter from "../../../assets/images/icons/twitter.svg";
import Medium from "../../../assets/images/icons/medium.svg";
import Linkedin from "../../../assets/images/icons/linkedin.svg";
import { useState } from "react";
import Modal from "../../../components/modal/Modal";
import Data from "../../../assets/data/bannarV3";
import { useEffect } from "react";
import Deposite from "../../../components/modal/Deposite";
import axios from "axios";
import TransakWidget from "../../../components/transak/Transak";
import { useWeb3AuthSigner } from "../../../components/context/web3-auth-signer";
import { alchemy } from "../../../utils/alchemy";

// eslint-disable-next-line react/prop-types
// const PopupWrapper = styled.div`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   background: #000; /* White background */
//   padding: 10px;
//   border: 1px solid #fff;
//   border-radius: 4px;
//   color: #fff; /* Black text */
//   z-index: 999;
//   display: ${({ open }) => (open ? "block" : "none")};
//   text-align: left; /* Adjust text alignment as needed */
//   width: 300px; /* Adjust the width as needed */
//   padding: 18px;
//   /* Add background image styling */
//   background-image: url(${MeshGradModal}); /* Replace with your desired background image */
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: cover;
// `;

// const PopupContent = styled.div`
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end; /* Move the icon to the right */
// `;

// const PopupText = styled.p`
//   color: #fff;

//   margin-top: 3px; /* Add a 3px gap between the icon and text */
// `;

// const Link = styled.a`
//   color: #fff; /* Set link color to blue by default */
//   text-decoration: underline; /* Remove the default underline */
//   cursor: pointer;
//   border-bottom: 1px solid white;
//   transition: text-decoration 0.2s; /* Add a smooth transition effect */

//   // &:hover {
//   //   text-decoration: underline; /* Add underline on hover */
//   // }
// `;

const Banner = () => {
  const code1 = localStorage.getItem("code");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    setOpenModule,
    setAccesscodeopen,
    accountAddress,
    setusdcbalace,
    setBlokcbalace,
    settotalblokcbalance,
    totalblokcbalance,
    web3AuthSigner,
    setTotaluser,
    setArbbalace,
  } = useWeb3AuthSigner();

  const modalHandle = () => {
    if (!code1) {
      setAccesscodeopen(true);
    } else if (!web3AuthSigner) {
      setOpenModule(true);
    } else {
      setIsModalOpen(true);
    }
  };
  const [opendeposit, setPpendeposit] = useState(false);

  //const [showPopup, setShowPopup] = useState(false);
  const [transakopen, setTransak] = useState(false);
  const [persantage, setpersentage] = useState("");
  const [totalblokcsale, setTotalblokcsale] = useState(0);
  const [totalB, SetTotalB] = useState(0);
  //console.log("totalblokcsale-->", totalblokcsale);

  //console.log("Totalsum-->", TotalsamBlokc);

  const TotalsaleEth = 115679450;

  const tottalblokc = 200000000;

  useEffect(() => {
    if (totalblokcsale) {
      const TotalsamBlokc = TotalsaleEth + totalblokcsale;
      // console.log("🚀 ~ useEffect ~ TotalsamBlokc:", TotalsamBlokc);
      const TotalsamBlokcFormatted =
        (TotalsamBlokc / 1000000).toFixed(1) + " Million";
      // console.log(
      //   "🚀 ~ useEffect ~ TotalsamBlokcFormatted:",
      //   TotalsamBlokcFormatted
      // );
      SetTotalB(TotalsamBlokcFormatted);
      const totalpersantage = ((TotalsamBlokc / tottalblokc) * 100).toFixed(1);
      // console.log("🚀 ~ useEffect ~ totalpersantage:", totalpersantage);
      setpersentage(totalpersantage);
    }
  }, [totalblokcsale, totalblokcbalance]);

  const main = async () => {
    if (accountAddress) {
      // Wallet address
      const walletAddress = accountAddress;

      // USDC contract address
      const contractAddress = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831";
      const numDecimals = 6;

      // Get the latest USDC balance
      let balance = await alchemy.core.getTokenBalances(walletAddress, [
        contractAddress,
      ]);
      balance = balance["tokenBalances"][0]["tokenBalance"];
      balance = (parseInt(balance) / 10 ** numDecimals).toString(); // Convert to string
      //console.log("Balance:", balance, "USDC");

      // Truncate to 3 decimal places without rounding
      const truncatedBalance = (
        Math.floor(parseFloat(balance) * 1000) / 1000
      ).toFixed(3);

      console.log("Balance:", truncatedBalance, "USDC");
      setusdcbalace(truncatedBalance);
    }
  };

  const main1 = async () => {
    if (accountAddress) {
      // Wallet address
      const walletAddress = accountAddress;

      // usdc contract address
      const contractAddress = "0xBC4d9D3Dfe6AB1D36Ede90050ce96FCB937469f0";
      const numDecimals = 18;

      // Get latest usdc balance
      let balance = await alchemy.core.getTokenBalances(walletAddress, [
        contractAddress,
      ]);
      balance = balance["tokenBalances"][0]["tokenBalance"];

      balance = Math.floor(parseInt(balance) / 10 ** numDecimals).toFixed(3); // Use Math.floor here
      //console.log("Balance:", balance, "BLOKC");
      setBlokcbalace(balance);
    }
  };

  const Arb = async () => {
    if (accountAddress) {
      // Wallet address
      const walletAddress = accountAddress;

      // USDC contract address
      const contractAddress = "0x25d887Ce7a35172C62FeBFD67a1856F20FaEbB00";
      const numDecimals = 18;

      // Get the latest USDC balance
      let balance = await alchemy.core.getTokenBalances(walletAddress, [
        contractAddress,
      ]);
      balance = balance["tokenBalances"][0]["tokenBalance"];
      balance = (parseInt(balance) / 10 ** numDecimals).toString(); // Convert to string
      //console.log("Balance:", balance, "USDC");

      // Truncate to 3 decimal places without rounding
      const truncatedBalance = (
        Math.floor(parseFloat(balance) * 1000) / 1000
      ).toFixed(3);

      // console.log("Balance:", truncatedBalance, "USDC");
      setArbbalace(truncatedBalance);
    }
  };

  const totalblokcbalancebalance = async () => {
    // Wallet address
    const walletAddress = "0x385dd520cA9F1Ad50882B0dBdd5A25269Ea2a5f9";

    // usdc contract address
    const contractAddress = "0xBC4d9D3Dfe6AB1D36Ede90050ce96FCB937469f0";
    const numDecimals = 18;

    // Get latest usdc balance
    let balance = await alchemy.core.getTokenBalances(walletAddress, [
      contractAddress,
    ]);
    balance = balance["tokenBalances"][0]["tokenBalance"];

    balance = Math.floor(parseInt(balance) / 10 ** numDecimals); // Use Math.floor here
    settotalblokcbalance(balance);
    //console.log("BalanceTOTAL:", balance, "BLOKC");
  };

  const calculateTotalBlokcAmount = (users) => {
    let totalBlokcAmount = 0;
    users.forEach((userData) => {
      totalBlokcAmount += userData.BlokcAmount;
    });
    return totalBlokcAmount;
  };

  const userdatas = async () => {
    try {
      const response = await axios.get(`https://core.blokcapital.io/userData`);
      // console.log("userData response-->", response?.data?.data?.final);
      const user = await response?.data?.data?.final;

      setTotaluser(user);
      const totalBlokcAmount = calculateTotalBlokcAmount(user);
      console.log("totalBlokcAmount-->", totalBlokcAmount);
      setTotalblokcsale(totalBlokcAmount);
    } catch (error) {
      console.log("error-->", error);
    }
  };

  useEffect(() => {
    if (accountAddress) {
      main();
      main1();
      totalblokcbalancebalance();
      userdatas();
      Arb();
    }
  }, [accountAddress]);

  return (
    <>
      <BannerWrapper>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-40 text-center">
                <div className="mb-20">
                  <h5 className="ff-outfit fw-600 text-white text-uppercase">
                    Private Sale Ends in{" "}
                  </h5>
                </div>
                <div className="mb-20 d-flex justify-content-center">
                  <Countdown font="orbitron" />
                </div>
                <div className="mb-20">
                  <h1 className="banner-title">
                    {Data.title}
                    <br />
                    {Data.titleExtra}
                  </h1>
                </div>
                <h5 className="ff-outfit text-white">{Data.subtitle}</h5>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-8">
              {accountAddress && (
                <>
                  <div className="md:block hidden">
                    <div className="mb-2 flex justify-between">
                      <h5 className="ff-orbitron fs-15 fw-600 text-white">
                        Private Round: {persantage}% Of Sale Completed!
                      </h5>
                      <h5 className="ff-orbitron fs-15 fw-600 text-white">
                        {totalB} Of 200 Million BLOKC Sold
                      </h5>
                    </div>
                  </div>
                  <div className="md:hidden block">
                    <div className="mb-2 flex flex-col justify-center items-center text-center">
                      <h5 className="ff-orbitron fs-14 fw-600 text-white">
                        Private Round: {persantage}% Of Sale Completed!
                      </h5>
                      <h5 className="ff-orbitron fs-14 fw-600 text-white">
                        {totalB} Of 200 Million BLOKC Sold
                      </h5>
                    </div>
                  </div>
                  <div className="mb-30">
                    <Progressbar done={persantage} variant="dashed" />
                  </div>
                </>
              )}
              <div className="mb-30 text-center">
                <p className="ff-orbitron fs-15 fw-600 text-white">
                  1 BLOKC = 0.001 USDC
                </p>
                {/* <p className="ff-orbitron fs-15 fw-600 text-white">
                  NEXT STAGE PRICE = 0.001 ETH
                </p> */}
              </div>
              <div className="mb-74 d-flex align-items-center justify-content-center">
                <Button variant="gradient" onClick={modalHandle}>
                  Buy now
                </Button>
              </div>
              <ul className="social-links">
                <li>
                  <a
                    href="https://t.me/BLOKCapital"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Telegram} alt="icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="http://twitter.com/blok_cap"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Twitter} alt="icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://medium.com/blokcapital"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Medium} alt="icon" />
                  </a>
                </li>
                <li>
                  <a
                    href="http://linkedin.com/company/blok-capital"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="icon" />
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://discord.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Discord} alt="icon" />
                  </a>
                </li> */}
                {/* <li>
                  <a
                    href="https://www.reddit.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Reddit} alt="icon" />
                  </a>
                </li> */}
                {/* <li>
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Instagram} alt="icon" />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </BannerWrapper>
      {isModalOpen && (
        <Modal
          setIsModalOpen={setIsModalOpen}
          setPpendeposit={setPpendeposit}
        />
      )}
      {opendeposit && (
        <Deposite
          setPpendeposit={setPpendeposit}
          setIsModalOpen={setIsModalOpen}
          setTransak={setTransak}
        />
      )}

      {transakopen && <TransakWidget setTransak={setTransak} />}
      {/* <PopupWrapper open={showPopup}>
        <AiOutlineClose
          onClick={() => setShowPopup(false)}
          size={15}
          className="cursor-pointer"
        />
        <PopupContent>
          <PopupText>
            {" "}
            <Link href="https://private.blokcapital.io/" target="_blank">
              private.blokcapital.io
            </Link>{" "}
            is the only platform to buy BLOKC
          </PopupText>
        </PopupContent>
      </PopupWrapper> */}
    </>
  );
};

export default Banner;
